import React, { useEffect } from 'react'
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"

const PushLink = () => {
    const { search } = useLocation()
    useEffect(() => {
        if(search.includes("reactbook")) window.location.href = "https://www.amazon.co.jp/dp/B08XWMVX76"
        else if(search.includes("reactTSbook")) window.location.href = "https://www.amazon.co.jp/dp/B094Z1R281"
        else if(search.includes("react-book2")) window.location.href = "https://www.amazon.co.jp/dp/B09693ZJCV"
        else if(search.includes("react-TS-book2")) window.location.href = "https://www.amazon.co.jp/dp/B09Q57Q9T1"
        else if(search.includes("nextbook")) window.location.href = "https://www.amazon.co.jp/dp/B0C7746ZC4"
        else if(search.includes("astrobook")) window.location.href = "https://www.amazon.co.jp/dp/B0DMLKMTZX"
        else if(search.includes("next-fullstack-server-actions")) window.location.href = "https://www.amazon.co.jp/dp/B0DMQLP8Z9"
        else if(search.includes("gatsbybook")) window.location.href = "https://www.amazon.co.jp/dp/B0BNQBDHFH"
        else if(search.includes("jamstackbook")) window.location.href = "https://www.amazon.co.jp/dp/B094Z1R281"
        else if(search.includes("nodebook-intro")) window.location.href = "https://www.amazon.co.jp/dp/B09HQZ1BW8"
        else if(search.includes("nodebook-advanced")) window.location.href = "https://www.amazon.co.jp/dp/B09X56PSCQ"
        else if(search.includes("next-book2-1")) window.location.href = "https://www.amazon.co.jp/dp/B09YWSHR58"
        else if(search.includes("next-book2-2")) window.location.href = "https://www.amazon.co.jp/dp/B09YXSN96J"
        else if(search.includes("next-fullstack-app-1")) window.location.href = "https://www.amazon.co.jp/dp/B0DRHF4KGM"
        else if(search.includes("next-fullstack-app-2")) window.location.href = "https://www.amazon.co.jp/dp/B0DRHRKVYV"
        else if(search.includes("next-book2-2")) window.location.href = "https://www.amazon.co.jp/dp/B09YXSN96J"
        else if(search.includes("next-fullstack1-ts")) window.location.href = "https://www.amazon.co.jp/dp/B0BD552RD6"
        else if(search.includes("next-fullstack2-ts")) window.location.href = "https://www.amazon.co.jp/dp/B0BD55W8LW"
        else if(search.includes("mern-book-1")) window.location.href = "https://www.amazon.co.jp/dp/B0BJN4H1NF"
        else if(search.includes("mern-book-2")) window.location.href = "https://www.amazon.co.jp/dp/B0BJMM1ZBP"
        else if(search.includes("vuebook")) window.location.href = "https://www.amazon.co.jp/dp/B0B46QNTNL"
        else if(search.includes("nuxtbook")) window.location.href = "https://www.amazon.co.jp/dp/B0B5N8QJ2Y"
        else if(search.includes("jsbook")) window.location.href = "https://www.amazon.co.jp/dp/B0C9C9SVFG"
        else if(search.includes("proxy-server")) window.location.href = "https://www.amazon.co.jp/dp/B0BBMQS28M"
        else if(search.includes("amazon-writer-central")) window.location.href = "https://www.amazon.co.jp/mod728/e/B099Z51QF2/ref=ntt_dp_epwbk_0"
        else if(search.includes("monotein-demo")) window.location.href = "https://monotein-demo.site/?r=monotein"
        else if(search.includes("headless-wp-demo")) window.location.href = "https://monotein.com/blog/?r=headless-wp-demo" //"https://monotein-demo-wordpress.site/?r=monotein"
        else if(search.includes("zetaliteral")) window.location.href = "https://zeta-literal.site/?r=monotein"
        else if(search.includes("rafertiliser")) window.location.href = "https://ra-fertiliser.site/?r=monotein"
        else if(search.includes("moonagefilms")) window.location.href = "https://moonagefilms.site/?r=monotein"
        else if(search.includes("gamersmancave")) window.location.href = "https://gamersmancave-nl.com/?r=monotein"
        else if(search.includes("yukiomishima")) window.location.href = "https://yukio-mishima.site/?r=monotein"
        else if(search.includes("jpen")) window.location.href = "https://jpen.netlify.app/?r=monotein"
        else if(search.includes("plen")) window.location.href = "https://poland-news-in-english.netlify.app/?r=monotein"
        else if(search.includes("plua")) window.location.href = "https://poland-news-in-ukrainian.netlify.app/?r=monotein"
        else if(search.includes("conare")) window.location.href = "https://conare.me/?r=monotein"
        else if(search.includes("line-business")) window.location.href = "https://lin.ee/mftMFLz"
        else if(search.includes("ig-personal")) window.location.href = "https://www.instagram.com/2pqj99u58shq5aa6394v5xeglnqnljcyqp239l577k3w1yh7bc78xj9ax126l32zjuign2s7l8"
        else if(search.includes("ig-business")) window.location.href = "https://www.instagram.com/monotein_int/"
        else if(search.includes("twitter")) window.location.href = "https://twitter.com/monotein_"
        
        else if(search.includes("blog-contact-form")) window.location.href = "https://my-contact-page-monotein.netlify.app"

        else if(search.includes("react-master")) window.location.href = "https://monotein.base.shop/p/00001"
        else if(search.includes("ts-master")) window.location.href = "https://monotein.base.shop/p/00002"

        else navigate("/?r=reject-from-pushlink")
    }, [search])
    return <div></div>
}

export default PushLink